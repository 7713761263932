export const environment = {
  production: false,
  secretKey: "MbQeThWmZq4t7w!z%C*F-JaNdRfUjXn2",
  env:'develop',
  baseUrlAPI: 'https://rwb35eimx9.execute-api.us-east-1.amazonaws.com/dev',
  // baseUrlAPI: 'http://localhost:5000/dev',
  baseWssAPI: 'wss://7ck2h620b7.execute-api.us-east-1.amazonaws.com/dev',
  baseUrlStore: 'store.cbntech.xyz',
  baseUrlDomain: 'cbntech.xyz',
  baseUrlDomainPlatform: 'cbntech.xyz'
};
