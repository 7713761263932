import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AwsShopService } from 'src/app/services/aws-shop-service/aws-shop.service';
import { GlobalService } from 'src/app/services/global.service';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

  showFiles = true;
  files = [];
  extensionsAttr = [
    { ext:['xls', 'xlsx', 'csv'], color:'#1BBB53', icon:'fa-file-excel' },
    { ext:['txt'], color:'#03A9F4', icon:'fa-file-alt' },
    { ext:['doc', 'docx', 'docm' ,'aspx'], color:'#3C33FF', icon:'fa-file-word' },
    { ext:['jpg', 'jpeg', 'gif', 'png', 'bmp', 'psd'], color:'#BF00FC', icon:'fa-file-image' },
    { ext:['avi', 'mp4', 'mpeg', 'mwv'], color:'#00E9FC', icon:'fa-file-video' },
    { ext:['ppt', 'pptx', 'pptm'], color:'#FF5722', icon:'fa-file-powerpoint' },
    { ext:['pdf'], color:'#E91E63', icon:'fa-file-pdf' },
    { ext:['html', 'css', 'js', 'json'], color:'#E2D63D', icon:'fa-file-code' },
    { ext:['mp3', 'wav', 'wma'], color:'#D1AE2E', icon:'fa-file-audio' },
    { ext:['zip', 'rar', 'gzip'], color:'#A67711', icon:'fa-file-archive' },
    { ext:['etc'], color:'#9E9E9E', icon:'fa-file' }
  ];
  statusUploading = false;
  
  @Output() nextFiles = new EventEmitter<any>();
  @Input() type = '';
  @Input() typeValue = '';
  @Input() path = '';
  @Input() extensions = '';
  @Input() email = '';

  constructor(
    private globalService: GlobalService,
    private sharedService: AwsShopService
  ) { }

  ngOnInit(): void {
  }

  /**
   * on file drop handler
   */
   onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<File>) {
    for (const file of files) {
      if(file.size <= 26214400){
        let ext = file.name.split(".")[file.name.split(".").length - 1].toLowerCase()
        if(this.extensions.length > 0){
          let extRequired = this.extensions.split(",");
          if(extRequired.includes(ext)){
            let attr = this.extensionsAttr.find(c => c['ext'].includes(ext))
            let namefile: any = file.name;
            let objPendingUpload = {
              id: uuid(),
              status: 'PENDING',
              progress: 0,
              size: this.formatBytes(file.size, 2),
              name: namefile.replaceAll(/[^a-zA-Z 0-9.]+/g,'_'),
              icon: attr ? attr['icon'] : 'fa-file',
              color: attr ? attr['color'] : '#9e9e9e',
              file: file
            };
            this.files = this.files.concat([objPendingUpload]);
          }else{
            this.globalService.sendRequest({
              severity: 'error',
              summary: 'Error',
              detail: 'FILE_ERROR_EXTENSION',
              type: 'TOAST',
            });
          }
        }else{
          let attr = this.extensionsAttr.find(c => c['ext'].includes(ext))
          let namefile: any = file.name;
          let objPendingUpload = {
            id: uuid(),
            status: 'PENDING',
            progress: 0,
            size: this.formatBytes(file.size, 2),
            name: namefile.replaceAll(/[^a-zA-Z 0-9.]+/g,'_'),
            icon: attr ? attr['icon'] : 'fa-file',
            color: attr ? attr['color'] : '#9e9e9e',
            file: file
          };
          this.files = this.files.concat([objPendingUpload]);
        }
      }else{
        this.globalService.sendRequest({
          severity: 'error',
          summary: 'Error',
          detail: 'FILE_ERROR_SIZE',
          type: 'TOAST',
        });
      }
    }
    if(!this.statusUploading){
      this.statusUploading = true;
      let index = 0;
      for (let i = 0; i < this.files.length; i++) {
        if(this.files[i].status === 'PENDING'){
          index = i;
          break;
        }
      }
      this.uploadFiles(index);
    }
  }

  async uploadFiles(i){
    if(i < this.files.length){
      let objBody = { "type": this.type, "typeValue": this.typeValue,  "path": this.path, "name": this.files[i]['name'] , "email":this.email };
      let preSigned = await firstValueFrom(this.sharedService.uploadFileS3All(objBody));
      if(preSigned['status']){
        this.sharedService.uploadFileToS3({ url: preSigned['data']['url'], file: this.files[i]['file'] }).subscribe((event: HttpEvent<any>) => {
          /* console.log(event) */
          switch (event.type) {
            case HttpEventType.Sent:
              this.files[i].status = "PROGRESS";
              break;
            case HttpEventType.ResponseHeader:
              break;
            case HttpEventType.UploadProgress:
              this.files[i].progress = Math.round(event.loaded / event.total * 100);
              break;
            case HttpEventType.Response:
              let result = event;
              if(result && result['status'] === 200){
                this.files[i].status = "FINALIZED";
                this.files[i]['url'] = result['url'].split("?")[0];
                this.uploadFiles(i + 1);
                this.globalService.sendRequest({severity: 'success',summary: 'Éxito',detail: 'DOCUMENT_UPLOAD_SUCCESS',type: 'TOAST'});
              }else{
                this.files = this.files.filter(c => c['id'] != this.files[i]['id']);
                this.uploadFiles(i);
                this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
              }
          }
        }, error => { 
          this.files = this.files.filter(c => c['id'] != this.files[i]['id']);
          this.uploadFiles(i);
          this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
         });
      }else{
        this.files = this.files.filter(c => c['id'] != this.files[i]['id']);
        this.uploadFiles(i);
        this.globalService.sendRequest({ severity: 'error', summary: 'Error', detail: 'FILE_ERROR_UPLOAD', type: 'TOAST' });
      }
    }else{
      this.statusUploading = false;
    }
  }

  /**
   * Delete file
   * @param file 
   */
  deleteFile(file){
    this.files = this.files.filter(c => c['id'] != file['id']);
  }
  
  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
   formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  generateFiles(){
    if(this.files.length > 0){
      this.nextFiles.emit(this.files.map(c => {
        return {
          url: c['url'],
          name: c['name']
        }
      }))
    }else{
      this.nextFiles.emit([]);
    }
  }
}
