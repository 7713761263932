import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'primeng/sidebar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {PasswordModule} from 'primeng/password';
import {CheckboxModule} from 'primeng/checkbox';
import {DividerModule} from 'primeng/divider';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import { TagModule } from 'primeng/tag';
import {TableModule} from 'primeng/table';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ProgressBarModule} from 'primeng/progressbar';
import {DataViewModule} from 'primeng/dataview';
import {SpeedDialModule} from 'primeng/speeddial';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SidebarModule,
    OverlayPanelModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    TooltipModule,
    ToastModule,
    TabMenuModule,
    AvatarModule,
    AvatarGroupModule,
    PasswordModule,
    CheckboxModule,
    DividerModule,
    ConfirmDialogModule,
    DialogModule,
    TagModule,
    TableModule,
    ScrollPanelModule,
    ProgressBarModule,
    DataViewModule,
    SpeedDialModule
  ],
  exports:[
    SidebarModule,
    OverlayPanelModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    TooltipModule,
    ToastModule,
    TabMenuModule,
    AvatarModule,
    AvatarGroupModule,
    PasswordModule,
    CheckboxModule,
    DividerModule,
    ConfirmDialogModule,
    DialogModule,
    TagModule,
    TableModule,
    ScrollPanelModule,
    ProgressBarModule,
    DataViewModule,
    SpeedDialModule
  ]
})
export class PrimengModule {}
