<div class="w-100">
    <div *ngFor="let file of files;let i = index;" class="col-12 flex justify-content-between">
        <div class="w-2rem flex align-items-center justify-content-center mr-2">
            <i class="far {{getIcon(file.name)}} text-2xl" [style.color]="getColor(file.name)"></i>
        </div>
        <div class="flex align-items-center overflow-hidden" style="width: 100%;">
            <span class="w-full overflow-hidden text-left text-overflow-ellipsis white-space-nowrap block">
                <a [href]="file.url" target="_blank" class="text-900 no-underline">{{file.name}}</a>
            </span>
        </div>
        <div *ngIf="type === 'EDIT'" class="flex align-items-center justify-content-center ml-2" style="width: 2.5rem;">
            <button pButton pRipple type="button" icon="fas fa-trash-alt" class="p-button-rounded p-button-danger p-button-text" (click)="deleteFile(file, i)"></button>
        </div>
    </div>
</div>
