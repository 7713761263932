import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public lang: string;
  request = new BehaviorSubject<Object>(undefined);
  request$ = this.request.asObservable();
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
  ) {}

  changeLanguage(type: string) {
    this.lang = type;
    this.translateService.use(type);
  }
  sendRequest(data){
    this.request.next(data);
  }
}
