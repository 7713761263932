import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-view-catalogue',
  templateUrl: './view-catalogue.component.html',
  styleUrls: ['./view-catalogue.component.scss']
})
export class ViewCatalogueComponent implements OnInit {
  @Input() item = null;
  @Input() optionCatalogue = "VISIBLE";
  previewSidebarImage = false;
  itemsSummary = [
    {
      icon: 'fas fa-photo-video',
      command: () => {
        this.previewSidebarImage = true
      },
    }
  ]
  constructor() { }

  ngOnInit(): void {
    /* console.log(this.item) */
  }


}
