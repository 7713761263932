<p-sidebar [(visible)]="previewSidebarImage" [fullScreen]="true" [blockScroll]="true" appendTo="body" styleClass="sidebarPreview">
    <div class="grid m-0 list-preview-photo-video relative overflow-hidden">
        <button pButton pRipple type="button" icon="pi pi-times" class="close-preview p-button-rounded p-button-secondary" (click)="closePreview()"></button>
        <div class="col-12 p-0 relative" [ngClass]="{'hidden': listPreview.length === 0 }">
            <swiper [spaceBetween]="10" [navigation]="true" class="mySwiper-preview" [zoom]="true"
                [pagination]="{clickable: true}" (swiper)="onSwiper($event)">
                <ng-template swiperSlide *ngFor="let item of listPreview">
                    <ng-container *ngIf="item.type === 'PHOTO'">
                        <button pButton pRipple type="button" icon="fas fa-search-plus"
                        class="p-button-rounded p-button-secondary zoom-in" (click)="zoomIn()"></button>
                        <button pButton pRipple type="button" icon="fas fa-search-minus"
                            class="p-button-rounded p-button-secondary zoom-out" (click)="zoomOut()"></button>
                        <div class="w-full h-full relative">
                            <div class="swiper-zoom-container relative">
                                <img src="{{item.url}}" />
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.type === 'VIDEO'">
                        <div class="w-full h-full relative">
                            <div class="swiper-zoom-container relative">
                                <video id="background-video" loop controls controlsList="nodownload" style="    width: 100%;
                                height: 100%;">
                                    <source [src]="item.url">
                                </video>
                            </div>
                        </div>
                    </ng-container>
                </ng-template>
            </swiper>
        </div>
    </div>
</p-sidebar>
