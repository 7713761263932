import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { 
    path: '', 
    loadChildren: () => import('./modules/home-store/home-store.module').then(m => m.HomeStoreModule)
  },
  { 
    path: ':company', 
    loadChildren: () => import('./modules/home-shop/home-shop.module').then(m => m.HomeShopModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
