<ng-container *ngIf="item">

    <div class="relative card-catalogue shadow-5 cursor-pointer" style="border-radius: 24px;" (click)="previewSidebarImage = true">
        <swiper [navigation]="false" class="mySwiperCatalogue" [autoplay]="{ delay: 2500, disableOnInteraction: false}">
            <ng-container *ngIf="item.files.length > 0">
                <ng-template swiperSlide *ngFor="let itemSecond of item.files">
                    <img *ngIf="itemSecond.type === 'PHOTO'" [src]="itemSecond.url" alt="" srcset="">
                    <img *ngIf="itemSecond.type === 'VIDEO'" [src]="itemSecond.urlImg" alt="" srcset="">
                </ng-template>
            </ng-container>
            <ng-container *ngIf="item.files.length === 0">
                <ng-template swiperSlide>
                    <img src="https://provcatalogue.s3.us-east-2.amazonaws.com/no_image_camera.jpg" alt="" srcset="">
                </ng-template>
            </ng-container>
        </swiper>

        <!-- <ng-container *ngIf="optionCatalogue != 'OVERLAPPED'">
            <div class="title-catalogue">

            </div>
            
            <div class="tex-catalogue flex align-items-start justify-content-between">
                <h3 style="font-size: 16px;">{{item.catalogueName}}</h3>
                <div class="speedDial-btn">
                    <p-speedDial [model]="itemsSummary" radius="20" direction="down" appendTo="body"
                    showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-text p-button-white">
                    </p-speedDial>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="optionCatalogue === 'OVERLAPPED'">
            <div class="title-catalogue-hover">

            </div>
          
            <div class="tex-catalogue-hover">
                <h3 class="w-8" style="font-size: 16px;     margin-top: 7%;
                margin-left: 7%;">{{item.catalogueName}}</h3>
                <div class="speedDialBtn">
                    <p-speedDial styleClass="" [model]="itemsSummary" radius="20" direction="down" appendTo="body"
                        showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-text p-button-white">
                    </p-speedDial>
                </div>
            </div>
        </ng-container> -->

        <ng-container *ngIf="optionCatalogue != 'OVERLAPPED'">
            <div class="title-catalogue">

            </div>
            <div class="tex-catalogue flex align-items-center justify-content-between">
                <h3 class="my-4 mx-3">{{item.catalogueName}}</h3>
            </div>
        </ng-container>
        <ng-container *ngIf="optionCatalogue === 'OVERLAPPED'">
            <div class="title-catalogue-hover">

            </div>
            <div class="tex-catalogue-hover">
                <h3 class="my-4 mx-3">{{item.catalogueName}}</h3>
            </div>
    </ng-container>
    </div>
    <app-preview-photo-video [listPreview]="item['files']" (closeComponent)="previewSidebarImage = false"
        *ngIf="previewSidebarImage"></app-preview-photo-video>
</ng-container>