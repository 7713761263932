import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { ListFilesComponent } from 'src/app/shared/list-files/list-files.component';
import { TranslateModule } from '@ngx-translate/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { UploadFilesComponent } from 'src/app/shared/upload-files/upload-files.component';
import { PrimengModule } from '../primeng/primeng.module';
import { PreviewPhotoVideoComponent } from 'src/app/shared/preview-photo-video/preview-photo-video.component';
import { ViewCatalogueComponent } from 'src/app/components/shop-catalogue/view-catalogue/view-catalogue.component';
@NgModule({
  declarations: [
    ListFilesComponent,
    UploadFilesComponent,
    PreviewPhotoVideoComponent,
    ViewCatalogueComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    TranslateModule,
    GoogleMapsModule,
    PrimengModule,
  ],
  exports:[
    SwiperModule,
    ListFilesComponent,
    TranslateModule,
    GoogleMapsModule,
    UploadFilesComponent,
    PreviewPhotoVideoComponent,
    ViewCatalogueComponent
  ]
})
export class GlobalModule { }
