<router-outlet></router-outlet>
<p-toast></p-toast>
<p-dialog [closeOnEscape]="false" header="" [(visible)]="expiredSession" [style]="{width: '350px'}" [modal]="true"
    [draggable]="false" [resizable]="false" [closable]="false">
    <div class="grid">
        <div class="col-12 text-center">
            <i class="fas fa-user-clock" style="font-size: 3rem;"></i>
            <h3>Tiempo de sesión expirado</h3>
            <p>Por seguridad hemos finalizado tu sesión por inactividad</p>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="{{ 'AGREE' | translate}}" class="p-button-rounded p-button-warning w-button"
            (click)="signOff()"></button>
    </ng-template>
</p-dialog>