import { Component, EventEmitter, Input, OnInit, Output, ViewChild ,ElementRef } from '@angular/core';
import SwiperCore, {Zoom, Navigation } from "swiper";
SwiperCore.use([Zoom, Navigation]);
@Component({
  selector: 'app-preview-photo-video',
  templateUrl: './preview-photo-video.component.html',
  styleUrls: ['./preview-photo-video.component.scss']
})
export class PreviewPhotoVideoComponent implements OnInit  {
  previewSidebarImage = true;
  @Input() listPreview = [];
  swiper = null;
  indexSwiper = 0;
  @Output() closeComponent = new EventEmitter<Object>();
  constructor() { }

  ngOnInit(): void {
  
  }

  ngOnChanges(){
    
  }

  closePreview(){
    this.closeComponent.emit();
  }

  zoomIn(){
    this.swiper.zoom.in()
  }

  zoomOut(){
    this.swiper.zoom.out()
  }
  onSwiper(swiper){
    this.swiper = swiper;
    if(this.listPreview.length > 0){
      this.swiper.slideTo(this.indexSwiper);
    }
  }
}
