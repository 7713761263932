import { Component, OnInit } from '@angular/core';
import { GlobalService } from './services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { SessionService } from './services/session.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit{
  title = 'store-cbn';
  expiredSession = false;
  constructor(
    private primengConfig: PrimeNGConfig,
    private messageService: MessageService,
    private globalService: GlobalService,
    public trans: TranslateService,
    private sessionService: SessionService
  ) {
    this.globalService.request.subscribe((res) => {
      if (res) {
        switch (res['type']) {
          case 'TOAST':
            this.showToast(res);
            break;
          default:
            break;
        }
      }
    });
  }
  ngOnInit() {
    this.set_initial_setup();
    this.primengConfig.ripple = true;
  }
  set_initial_setup() {
    let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es';
    localStorage.setItem('lang', lang);
    this.globalService.changeLanguage(lang);
  }

  async showToast(data) {
    let detail = await new Promise((resolve, reject) => {
      this.trans.get(data['detail']).subscribe((res) => {
        resolve(res);
      });
    });

    let summary = await new Promise((resolve, reject) => {
      this.trans.get(data['summary']).subscribe((res) => {
        resolve(res);
      });
    });

    if (data['detail'] === 'TOKEN_REVOKED' || data['detail'] === "TOKEN_UNAUTHORIZED") {
      this.expiredSession = true;
    }else{
      let messageToast: any = {
        severity: data['severity'],
        summary: summary,
        detail: detail,
        life: 5000,
      };
      this.messageService.add(messageToast);
    }
  }
  async signOff() {
    this.sessionService.deletedSession();
    window.location = window.location;
  }
}
