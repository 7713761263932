import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/services/session.service';
@Injectable({
  providedIn: 'root'
})
export class AwsShopService {
  
  constructor(private http: HttpClient, private sessionService: SessionService) { }
  private httpOptionsLogin = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id': 'apliclientesksks',
      'client-hash': 'apliclientesksks',
    }),
  };
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'client-id':'apliclientesksks',
      'client-hash':'apliclientesksks'
    })
  };

  signIn(body) {
    return this.http.post(
      `${environment.baseUrlAPI}/user/signIn`,
      body,
      this.httpOptionsLogin
    );
  }

  getCompanyStoreById(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getCompanyStoreById`, body,  this.httpOptions);
  }

  getProfileCompanyStore(body){
    if(this.sessionService.statusSession){
      let httpOptionstoken = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'client-id':'apliclientesksks',
          'client-hash':'apliclientesksks',
          'Auth': this.sessionService.token
        })
      };
      return this.http.post(`${environment.baseUrlAPI}/user/getProfileCompanyStore`, body, httpOptionstoken);
    }else{
      let httpOptionsNottoken = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'client-id':'apliclientesksks',
          'client-hash':'apliclientesksks'
        })
      };
      return this.http.post(`${environment.baseUrlAPI}/user/getProfileCompanyStore`, body, httpOptionsNottoken);
    }
  }

  getCompanyContactStore(body){
    if(this.sessionService.statusSession){
      let httpOptionstoken = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'client-id':'apliclientesksks',
          'client-hash':'apliclientesksks',
          'Auth': this.sessionService.token
        })
      };
      return this.http.post(`${environment.baseUrlAPI}/user/getCompanyContactStore`, body, httpOptionstoken);
    }else{
      let httpOptionsNottoken = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'client-id':'apliclientesksks',
          'client-hash':'apliclientesksks'
        })
      };
      return this.http.post(`${environment.baseUrlAPI}/user/getCompanyContactStore`, body, httpOptionsNottoken);
    }
  }

  getCompanyCatalogueStore(body){
    return this.http.post(`${environment.baseUrlAPI}/user/getCompanyCatalogueStore`, body,  this.httpOptions);
  }

  getCompany(body){
    let  httpOptionstoken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/user/company`, body,  httpOptionstoken);
  }


  getCompaniesRequest() {
    let httpOptionstoken = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id':'apliclientesksks',
        'client-hash':'apliclientesksks',
        'Auth': this.sessionService.token
      })
    };
    return this.http.post(`${environment.baseUrlAPI}/user/userForCompanyList`,{}, httpOptionstoken);
  }

  logout() {
    let httpOptionsLogout = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'client-id': 'apliclientesksks',
        'client-hash': 'apliclientesksks',
        Auth: this.sessionService.revokeToken,
      }),
    };
    return this.http.post(`${environment.baseUrlAPI}/user/logout`,{},httpOptionsLogout);
  }

  createRecordsContactStore(body){
    /* console.log(body) */
    return this.http.post(`${environment.baseUrlAPI}/user/createRecordsContactStore`, body,  this.httpOptions);
  }

  

  uploadFileS3All(body){
    if(this.sessionService.statusSession){
      let httpOptionstoken = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'client-id':'apliclientesksks',
          'client-hash':'apliclientesksks',
          'Auth': this.sessionService.token
        })
      };
      return this.http.post(`${environment.baseUrlAPI}/user/uploadArrayFilesS3NotSign`, body, httpOptionstoken);
    }else{
      let httpOptionsNottoken = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'client-id':'apliclientesksks',
          'client-hash':'apliclientesksks'
        })
      };
      return this.http.post(`${environment.baseUrlAPI}/user/uploadArrayFilesS3NotSign`, body, httpOptionsNottoken);
    }
  }

  uploadFileToS3(body){
    let httpFormaData = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
    }); 
    return this.http.put(body['url'], body['file'], { headers: httpFormaData, reportProgress: true, observe: 'events' });
  }
}
